import React from 'react';
import styled from 'styled-components';
import Modal from '../UiComponents/Modal';
import Button from '../UiComponents/Button';
import theme from '../../UI/theme';

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    min-height: 300px;
`;

const LostBtConnectionModal = ({show, close, reconnect}) => {
    // Add your component logic here

    return (
        <Modal show={show} close={() => {}} >
            <StyledContainer>
                <h1 style={{color: theme.colors.greenEnergy, margin: '0px'}}>Whoops!</h1>
                <p>We seem to have lost connection with the Hub!</p>
                <p>No worries, simply</p>
                <Button primary onClick={reconnect}>Reconnect and continue</Button>
                <p>or</p>
                <Button tertiary onClick={close}>Close and restart</Button>
            </StyledContainer>
        </Modal>
    );
};

export default LostBtConnectionModal;