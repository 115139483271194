const UUID = {
    SERVICE: 0x0001,

    WIFI: 0x0011,
    WIFI_NOTIFICATION: 0x0012,

    CLIENT: 0x0021,
    CLIENT_NOTIFICATION: 0x0022,

    INSTALLER: 0x0031,
    INSTALLER_NOTIFICATION: 0x0032,
    INSTALLER_INVITE: 0x0033,

    USERS: 0x0041,
    USERS_NOTIFICATION: 0x0042,

    ETHERNET: 0x0051,
    ETHERNET_NOTIFICATION: 0x0052,

    AVAILABLE_VENUES: 0x0061,
    AVAILABLE_VENUES_NOTIFICATION: 0x0062,

    COMPLETE: 0x0101,
    COMPLETE_NOTIFICATION: 0x0102,

    CLEANUP_AND_CONTINUE: 0x0201,
}

const SUBSCRIPTION_TYPES = {
    WIFI: 'WIFI',
    CLIENT: 'CLIENT',
    INSTALLER: 'INSTALLER',
    USERS: 'USERS',
    VENUES: 'VENUES',
}

class HubBluetoothConnection {
    constructor(device, server, service) {
        this.device = device;
        this.server = server;
        this.service = service;
        this.isConnected = false;
        this.subscriptions = [];
    }

    parse(string) {
        var parsedData = null;
        try {
            parsedData =  JSON.parse(string);
        } catch (error) {
            parsedData = {value: string};
        }
        return parsedData;
    }

    async fetchData(uuid) {
        try{
            const characteristic = await this.service.getCharacteristic(uuid);
            const dataBuffer = await characteristic.readValue();
            const dataString = new TextDecoder().decode(dataBuffer);
            var parsedData = this.parse(dataString);
            return (parsedData);
        } catch (error) {
            console.error('Error fetching data');
            console.error(error);
            return false;
        }
    }
    
    async postData (data, uuid) {
        try {
            const dataString = JSON.stringify(data);
            const characteristic = await this.service.getCharacteristic(uuid);
            const returnedOnWrite = await characteristic.writeValue(new TextEncoder().encode(dataString));
            const decodedReturnedOnWrite = new TextDecoder().decode(returnedOnWrite);
            return true;
        } catch (error) {
            console.error('Error posting data');
            console.error(error);
            return false;
        }
    }

    async subscribe(uuid, type, callback) {
        try {
            const isSubscribed = this.subscriptions.find(sub => sub.type === type);
            if (isSubscribed) return false;
            const characteristic = await this.service.getCharacteristic(uuid);
            await characteristic.startNotifications();
            characteristic.addEventListener('characteristicvaluechanged', callback);
            const subscription = {type: type, characteristic: characteristic};
            this.subscriptions.push(subscription);
            return true;
        } catch (error) {
            console.error('Error subscribing to notification');
            console.error(error);
            return false;
        }
    }

    async unsubscribe(type, callback) {
        try {
            const subscription = this.subscriptions.find(sub => sub.type === type);
            if (!subscription) return false;
            subscription.characteristic.stopNotifications()
            subscription.characteristic.removeEventListener('characteristicvaluechanged', callback);
            this.subscriptions = this.subscriptions.filter(sub => sub.type !== type);
            return true;
        } catch (error) {
            console.error('Error unsubscribing from notification');
            console.error(error);
            return false;
        }
    }

    async getWifiData() {
        const wifiData = await this.fetchData(UUID.WIFI);
        return wifiData;
    }

    setWifiData(data) {
        const newWifiInfo = this.postData(data, UUID.WIFI);
        return newWifiInfo;
    }

    subscribeWifiData(callback) {
        const wifiSubscription = this.subscribe(UUID.WIFI_NOTIFICATION, SUBSCRIPTION_TYPES.WIFI , callback);
        return wifiSubscription;
    }

    unsubscribeWifiData(callback) {
        const wifiUnsubscribe = this.unsubscribe(SUBSCRIPTION_TYPES.WIFI, callback);
        return wifiUnsubscribe;
    }

    async getClientData() {
        const clientData = await this.fetchData(UUID.CLIENT);
        return clientData;
    }
    
    setClientData(data) {
        const clientData = this.postData(data, UUID.CLIENT);
        return clientData;
    }

    subscribeClientData(callback) {
        const clientSubscription = this.subscribe(UUID.CLIENT_NOTIFICATION, SUBSCRIPTION_TYPES.CLIENT, callback);
        return clientSubscription;
    }

    unsubscribeClientData(callback) { // need to pass!!! callback
        const clientUnsubscribe = this.unsubscribe(SUBSCRIPTION_TYPES.CLIENT, callback);
        return clientUnsubscribe;
    }

    async getAvailableVenuesData() {
        const availableVenues = await this.fetchData(UUID.AVAILABLE_VENUES);
        return availableVenues;
    }

    setAvailableVenuesData(data) {
        const availableVenues = this.postData(data, UUID.AVAILABLE_VENUES);
        return availableVenues;
    }

    subscribeAvailableVenuesData(callback) {
        const availableVenuesSubscription = this.subscribe(UUID.AVAILABLE_VENUES_NOTIFICATION, SUBSCRIPTION_TYPES.VENUES, callback);
        return availableVenuesSubscription;
    }

    unsubscribeAvailableVenuesData(callback) {
        const availableVenuesUnsubscribe = this.unsubscribe(SUBSCRIPTION_TYPES.VENUES, callback);
        return availableVenuesUnsubscribe;
    }

    async getInstallerData() {
        const installerData = await this.fetchData(UUID.INSTALLER);
        return installerData;
    }

    setInstallerData(data) {
        const installerData = this.postData(data, UUID.INSTALLER);
        return installerData;
    }

    subscribeInstallerData(callback) {
        const installerSubscription = this.subscribe(UUID.INSTALLER_NOTIFICATION, SUBSCRIPTION_TYPES.INSTALLER, callback);
        return installerSubscription;
    }

    unsubscribeInstallerData(callback) {
        const installerUnsubscribe = this.unsubscribe(SUBSCRIPTION_TYPES.INSTALLER, callback);
        return installerUnsubscribe;
    } 

    inviteInstaller(data) {
        const inviteData = this.postData(data, UUID.INSTALLER_INVITE);
        return inviteData;
    }

    async getUsersData() {
        const usersData = await this.fetchData(UUID.USERS);
        return usersData;
    }

    setUsersData(data) {
        const usersData = this.postData(data, UUID.USERS);
        return usersData;
    }

    subscribeUsersData(callback) {
        const usersSubscription = this.subscribe(UUID.USERS_NOTIFICATION, SUBSCRIPTION_TYPES.USERS, callback);
        return usersSubscription;
    }

    unsubscribeUsersData(callback) {
        const usersUnsubscribe = this.unsubscribe(SUBSCRIPTION_TYPES.USERS, callback);
        return usersUnsubscribe;
    }

    async getEthernetData() {
        const ethernetData = await this.fetchData(UUID.ETHERNET);
        return ethernetData;
    }

    setEthernetData(data) {
        const ethernetData = this.postData(data, UUID.ETHERNET);
        return ethernetData;
    }

    subscribeEthernetData(callback) {
        const ethernetSubscription = this.subscribe(UUID.ETHERNET_NOTIFICATION, SUBSCRIPTION_TYPES.ETHERNET, callback);
        return ethernetSubscription;
    }

    unsubscribeEthernetData(callback) {
        const ethernetUnsubscribe = this.unsubscribe(SUBSCRIPTION_TYPES.ETHERNET, callback);
        return ethernetUnsubscribe;
    }

    async getCompleteData() {
        const completeData = await this.fetchData(UUID.COMPLETE);
        return completeData;
    }

    setCompleteData(data) {
        const completeData = this.postData(data, UUID.COMPLETE);
        return completeData;
    }

    subscribeCompleteData(callback) {
        const completeSubscription = this.subscribe(UUID.COMPLETE_NOTIFICATION, SUBSCRIPTION_TYPES.COMPLETE, callback);
        return completeSubscription;
    }

    unsubscribeCompleteData(callback) {
        const completeUnsubscribe = this.unsubscribe(SUBSCRIPTION_TYPES.COMPLETE, callback);
        return completeUnsubscribe;
    }

    async cleanupAndContinue(data) {
        console.log('cleaning up and continuing');
        const posted = await this.postData(data, UUID.CLEANUP_AND_CONTINUE);
        return posted;
    }

    reset() {
        this.subscriptions = [];
    }

    disconnect() {
        this.server.device.gatt.disconnect();
    }

}

export default HubBluetoothConnection;

export {
    UUID
}