import heatmapTypes from "./heatmapTypes";

const elementsNeedUpdate = (floorElements, objects, idField, elementType) => {
    let needsUpdate = false;
    if (objects) {
        const relevantFloorElements = floorElements.filter(element => element.type === elementType);
        const hasNewZone = objects.some(object => !relevantFloorElements.find(element => element.floorElementId === object[idField]));
        const hasRemovedZone = relevantFloorElements.some(element => !objects.find(object => element.floorElementId === object[idField]))
        if (hasNewZone || hasRemovedZone) {
            needsUpdate = true;    
        }
    }
    return needsUpdate;
}

const floorElementsNeedUpdate = (floorElements, props) => {
    let needsUpdate = false;
    const elementsClasses = [{
        type: heatmapTypes.elementTypes.HUB,
        key: 'hubs',
        idField: 'hubId'
    }, {
        type: heatmapTypes.elementTypes.SENSOR,
        key: 'sensors',
        idField: 'sensorId'
    }, {
        type: heatmapTypes.elementTypes.PROCESSOR,
        key: 'processors',
        idField: 'processorId'
    }, {
        type: heatmapTypes.elementTypes.ZONE,
        key: 'zones',
        idField: 'zoneId'
    }];

    for (let i = 0; i<elementsClasses.length; i++) {
        const elementClass = elementsClasses[i];
        needsUpdate = elementsNeedUpdate(floorElements, props[elementClass.key], elementClass.idField, elementClass.type);
        if (needsUpdate) {
            return needsUpdate;
        }
    }
    return needsUpdate;
}

export {
    floorElementsNeedUpdate
}