import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../UiComponents/Button';
import { AnimationOverlay, SetupStatusContainer, SetupStepContainer, SkipButton, SkipButtonContainer, StepManagerContainer, ConnectionConfirmatoinContainer } from './SetupStepContainers';
import theme from '../../UI/theme';
import IconLogo from '../UiComponents/IconLogo';

const RotatingIconLogo = styled(IconLogo)`

    animation: rotate 20s ease-in-out infinite;

    @keyframes rotate {
        
        0% {
            transform: rotate3d(0, 1, 0, 0deg);
        }

        45% {
            transform: rotate3d(0, 1, 0, 0deg);
        }

        55% {   
            transform: rotate3d(0, 1, 0, 360deg);
        }

        100% {
            transform: rotate3d(0, 1, 0, 360deg);
        }
    }

`


const EthernetSetup = ({ hubBluetoothConnection, setSetupStep, SETUP_STEP, ethernet, setEthernet, wifi}) => {

    const [pending, setPending] = useState(false);

    useEffect(() => {
        syncData();
        hubBluetoothConnection.subscribeEthernetData(subscriptionCallback);
    }, []);

    useEffect(() => () => {
        hubBluetoothConnection.unsubscribeEthernetData(subscriptionCallback);
    }, []);

    function subscriptionCallback(event) {
        setPending(false);
        const value = new TextDecoder().decode(event.target.value);
        var parsedData = hubBluetoothConnection.parse(value);
        const notification = parsedData.notification;
        const data = parsedData.data;

        switch (notification) {
            case 'NETWORK':
                updateEthernet(data);
                break;

            case 'PENDING':
                setPending(true);
                break;

            default:
                break;
        }
    }

    function updateEthernet(data) {
        var updatedEthernet = {...ethernet};
        updatedEthernet.ip = data.ip;
        updatedEthernet.internet = data.internet;
        updatedEthernet.server = data.server;
        setEthernet(updatedEthernet);
    }

    async function syncData() {
        const ethernetInfo = await hubBluetoothConnection.getEthernetData();
        if (ethernetInfo) {
            setEthernet({ip: ethernetInfo.ip, internet: ethernetInfo.internet});
        }
    }

    function next() {
        if (ethernet.internet && wifi.ip) setSetupStep(SETUP_STEP.WIFI);
        else if (ethernet.internet) setSetupStep(SETUP_STEP.INSTALLER);
        else skip();
    }

    function back() {
    }

    function skip() {
        setSetupStep(SETUP_STEP.WIFI);
    }

    return (
        <SetupStepContainer>
            <AnimationOverlay/>
            <h2>Network</h2>
            <h4 style={{margin: '0px 0px 10px 0px', textAlign: 'center'}}>Connect Hub to a cabled network</h4>
            <p style={{margin: '0px 0px 10px 0px', textAlign: 'center'}}>This should be the same network as the music system</p>

        <div style={{minHeight: '250px', display: 'flex' , justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            {ethernet.ip ?
                <p style={{textAlign: 'center'}}>Network connection established</p>
            :<>
                <p style={{marginTop: '30px', textAlign: 'center'}}>Waiting, please connect to a cabled network...</p>
                <RotatingIconLogo preloader height={'50px'} width={'50px'}/>
            </>}

            {ethernet.internet && !ethernet.server ?<>
                <p style={{marginTop: '30px', textAlign: 'center', color: theme.colors.raspberryRed}}>This network has internet, but Waved Hub can not reach its server. <br/>It might be blocked by a firewall, please conntacth the owner of this network</p>
            </>: null}
        </div>
            

            <SetupStatusContainer >
                <ConnectionConfirmatoinContainer>
                    <p>Ethernet Ip:</p>
                    {ethernet.ip ? <p style={{color: theme.colors.greenEnergy}}>{ethernet.ip}</p>
                    : <>{pending? <p style={{color: theme.colors.mellowYellow}}>Pending</p> : <p style={{color: theme.colors.raspberryRed}}>Undefined</p>}</> } 
                    <p >Local net:</p>
                    {ethernet.ip ? <p style={{color: theme.colors.greenEnergy}}>{' Connected'}</p> 
                    : <>{pending? <p style={{color: theme.colors.mellowYellow}}>Pending</p> : <p style={{color: theme.colors.raspberryRed}}>Not Connected</p>}</> } 
                    <p>Internet:</p>
                    {ethernet.internet ? <p style={{color: theme.colors.greenEnergy}}>{' Connected'}</p>
                    : <>{pending? <p style={{color: theme.colors.mellowYellow}}>Pending</p> : <p style={{color: theme.colors.raspberryRed}}>Not Connected</p>}</> } 
                    <p>Server:</p>
                    {ethernet.server ? <p style={{color: theme.colors.greenEnergy}}>{' Connected'}</p>
                    : <>{pending? <p style={{color: theme.colors.mellowYellow}}>Pending</p> : <p style={{color: theme.colors.raspberryRed}}>Not Connected</p>}</> } 
                </ConnectionConfirmatoinContainer>
            </SetupStatusContainer>

            <StepManagerContainer>
                <Button disabled tertiary small onClick={back}>Back</Button>
                <Button disabled={false} primary small onClick={next}>{ethernet.internet? 'Next' : 'Skip'}</Button>
            </StepManagerContainer>
            <SkipButtonContainer>
                <SkipButton onClick={skip}>I know what I'm dooing, jump to wifi setup</SkipButton> 
            </SkipButtonContainer>
        </SetupStepContainer>
    );
};

export default EthernetSetup;