import React from 'react';
import styled, {css} from 'styled-components';
import IconLogoStructure from './IconLogoStructure';
import theme from '../../UI/theme';

/**
 * Default: static logo with dark theme (greenEnergy + darkSpace)
 * 
 * props:
 *  light (uses colors: darkSpace + whiteSpace)
 *  reveal (reveal animation)
 *  preloader (preloader animation)
 */

const StyledIconLogo = styled.div`
    width: ${props => props.height || '80px'};
    height: ${props => props.width || '80px'};
    display: block;

    ${props => props.light && css`
        path {
            stroke: ${theme.colors.whiteSpace};
            stroke-width: 8px;
        };
        rect {
            fill: ${theme.colors.darkSpace};
        };
    `}

    ${props => props.reveal && css`
        
        path {
            animation: move-symbol 1s ease forwards;
            animation-delay: 500ms;
            stroke-dasharray: 200;
            stroke-dashoffset: 200;

            @keyframes move-symbol {
                0% {
                stroke-width: 0px;
                }
            
                100% {
                stroke-dashoffset: 0;
                }
            }
        }
        rect {
            animation: intro-symbol-bg 500ms ease forwards;
            animation-delay: 500ms;
            opacity: 0;

            @keyframes intro-symbol-bg {
                to {
                //transform: scale(1);
                opacity: 1;
                }
            }
        } 
    `}

    ${props => props.preloader && css`
        path {
            animation: preloader-symbol 750ms ease infinite alternate;
            animation-delay: 1250ms;
            stroke-dasharray: 200;
            stroke-dashoffset: 0;
            transform-origin: center center;
            fill: none;
            stroke: color(neutral, 100);
            stroke-width: 0;

            @keyframes preloader-symbol {
                0% {
                  stroke-width: 0;
                }
            
                100% {
                  stroke-width: 8px;
                }
            }
        }

        rect {
            animation: intro-symbol-bg 500ms ease forwards;
            animation-delay: 500ms;
            opacity: 0;

            @keyframes intro-symbol-bg {
                to {
                //transform: scale(1);
                opacity: 1;
                }
            }
        } 

    `}
`

const IconLogo = props => {

    return (
        <StyledIconLogo {...props}>
            <IconLogoStructure/>
        </StyledIconLogo>
    );
}

export default IconLogo;