import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getCustomer } from './scripts/datasource';
import StyleProvider from './UI/StyleProvider'
import './App.css';
import UserContext from './hooks/UserContext';
import AuthView from './views/AuthView';
import SettingsView from './views/SettingsView';
import HelpView from './views/HelpView';
import HeatmapView from './views/HeatmapView';
import MenuBar from './components/navigation/MenuBar';
import ViewContainer from './components/UiComponents/ViewContainer';
import SplashScreenView from './views/SplashScreenView';
import CenteredViewContainer from './components/UiComponents/CenteredViewContainer';
import Tracker from './components/analytics/Tracker';
import HeatmapContainer from './components/UiComponents/HeatmapContainer';
import AdminView from './views/AdminView';
import { usePageVisibility } from 'react-page-visibility';
import { getAppVersion } from './scripts/client';
import SupportView from './views/SupportView';
import NorthstarView from './views/NorthstarView';
import { chartJsSetup, checkResizeObserver } from './globalSetup';
import serverConnection from './scripts/server/ServerConnection';
import LocalSetupView from './components/setup/LocalSetupView';
import PanelView from './views/PanelView';
import InstallationView from './views/InstallationView';
import version from './version';
import LockedOutView from './views/LockedOutView';
import ServiceUnavailableView from './views/ServiceUnavailableView';
import { appStates } from './appTypes';
import LoadingView from './views/LoadingView';
import InsightsView from './views/InsightsView';
import { getCurrentUser } from './scripts/user';
import ResetPasswordView from './views/ResetPasswordView';
import { getUserPermissions, getUserVenues } from './scripts/permission';
import PermissionChecker from './components/permission/PermissionChecker';
import { permissionTypes, roles } from './components/permission/permissions';
import UserAdministrationView from './components/settings/user/userAdministration/UserAdministrationView';
import ForgotPasswordView from './components/authentication/ForgotPasswordView';
import SignUpView from './views/SignUpView';
import MyVenuesView from './views/MyVenuesView';
import { getDefaultVenueId } from './components/settings/user/userAdministration/userAdministrationUtils';
import { isLocalApp } from './scripts/server/server';
import MeterView from './components/settings/venue/meter/MeterView';
import NewInstalationView from './views/NewInstallView';

chartJsSetup()
checkResizeObserver()

const env = process.env.REACT_APP_ENVIRONMENT;
const hostType = process.env.REACT_APP_HOST_TYPE;
const LOCAL_APP_VERSION = version;

function App() {
  const [user, setUser] = useState({});
  const [customer, setCustomer] = useState();
  const [permissions, setPermissions] = useState();
  const [venues, setVenues] = useState();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [appState, setAppState] = useState(appStates.NONE);
  const isVisible = usePageVisibility();

  useEffect(() => {
    onLoadHandler();
  }, []);

  useEffect(() => {
    if (isVisible) {
      checkForUpdate();
      serverConnection.onVisible();
      checkLockedOut();
    }
  }, [isVisible]);

  const checkForUpdate = async () => {
    var appVersion = await getAppVersion();
    if (!isNaN(Number(appVersion))) {
      if (appVersion > LOCAL_APP_VERSION) {
        var refreshCount = Number(localStorage.getItem('WAVED_APP_REFRESH_COUNT'));
        if (!refreshCount) {
          localStorage.setItem('WAVED_APP_REFRESH_COUNT', 1)
          window.location.reload();
        }
      } else if (appVersion == LOCAL_APP_VERSION) {
        localStorage.setItem('WAVED_APP_REFRESH_COUNT', 0)
      }
    }
  }

  const checkLockedOut = async () => {
    try {
      if (customer) {
        const updatedCustomer = await getCustomer(customer.customerId);
        if (updatedCustomer.lockedOut !== customer.lockedOut) {
          setCustomer(updatedCustomer);
        }
      }
    } catch (err) { }
  }

  const onLoadHandler = async () => {
    const loadingSpinnerTimeout = setTimeout(() => {
      setAppState(appStates.LOADING);
    }, 500);
    try {
      const userRes = await getCurrentUser();
      if (userRes) {
        if (isLocalApp()) {
          await setCustomerHandler(userRes.defaultVenueId);
          setPermissions([]);
          setVenues([]);
        } else {
          const permissionsRes = await getUserPermissions();
          const venuesRes = await getUserVenues();
          const defaultVenueId = getDefaultVenueId(userRes, venuesRes);
          if (defaultVenueId) {
            await setCustomerHandler(defaultVenueId);
          }
          setPermissions(permissionsRes);
          setVenues(venuesRes);
        }
        setUser(userRes);
      }

      clearTimeout(loadingSpinnerTimeout);
      setAppState(appStates.SUCCESS);
    } catch (err) {
      clearTimeout(loadingSpinnerTimeout);
      setAppState(appStates.SERVICE_UNAVAILABLE);
    }
  }

  const setCustomerHandler = async (customerId) => {
    try {
      if (customerId !== null) {
        const customerRes = await getCustomer(customerId);
        serverConnection.setCustomerId(customerId);
        setCustomer(customerRes);
      } else {
        setCustomer(null)
      }

    } catch (err) {
      console.log('Failed to get customer');
    }
  }

  const setAuthenticated = (authenticated) => {
    onLoadHandler();
  }


  if (showSplashScreen) {
    setTimeout(() => {
      setShowSplashScreen(false);
    }, 1500);
    return (
      <SplashScreenView></SplashScreenView>
    );
  } else {
    return (
      <StyleProvider className="App">
        <Router>
          {env === 'PRODUCTION' ? <>
            <Tracker />
          </> : <></>}
          <div>
            {appState === appStates.SUCCESS ? <>
              <Switch>

                <Route path="/signUp/:signUpRequestId">
                  <ViewContainer>
                    <SignUpView />
                  </ViewContainer>
                </Route>

                <Route path="/forgotPassword">
                  <ViewContainer>
                    <ForgotPasswordView />
                  </ViewContainer>
                </Route>

                <Route path="/passwordReset/:resetId?">
                  <ViewContainer>
                    <ResetPasswordView />
                  </ViewContainer>
                </Route>

                {user && customer && venues && customer.customerId ? <>
                  <UserContext.Provider value={{
                    user,
                    setUser,
                    permissions,
                    customer
                  }}>
                    {customer.lockedOut && !['admin', 'impersonator'].includes(user.role) ? <>
                      <LockedOutView />
                    </> : <>

                      <Switch>

                        <Route exact path="/new-install">
                          <NewInstalationView/>
                        </Route>  

                        <MenuBar
                          user={user}
                          customer={customer}
                          setCustomer={setCustomerHandler}
                          env={env}
                          venues={venues}
                        />

                      </Switch>

                      <Route path="/insights">
                        <PermissionChecker permissionType={permissionTypes.insightsRead}>
                          <InsightsView
                            customer={customer}
                          />
                        </PermissionChecker>
                      </Route>

                      <Route path="/settings">
                        <ViewContainer>
                          <SettingsView customerId={customer.customerId} />
                        </ViewContainer>
                      </Route>

                      <Route path="/support">
                        <ViewContainer>
                          <HelpView address={customer.address} />
                        </ViewContainer>
                      </Route>

                      <Route path="/installation">
                        <PermissionChecker permissionType={permissionTypes.installationViewRead}>
                          <InstallationView customerId={customer.customerId} setCustomer={setCustomerHandler} />
                        </PermissionChecker>
                      </Route>

                      <Route path="/meter">
                        <PermissionChecker role={roles.admin}>
                          <MeterView />
                        </PermissionChecker>
                      </Route>

                      <Route path="/userAdministration">
                        <PermissionChecker permissionType={permissionTypes.userAdministrationRead}>
                          <UserAdministrationView />
                        </PermissionChecker>
                      </Route>

                      <Route exact path="/">
                        <PermissionChecker permissionType={permissionTypes.panelViewRead}>
                          <PanelView customerId={customer.customerId} />
                        </PermissionChecker>
                      </Route>

                      <Route path="/map">
                        <HeatmapContainer>
                          <PermissionChecker permissionType={permissionTypes.panelViewRead}>
                            <HeatmapView customerId={customer.customerId} user={user} />
                          </PermissionChecker>
                        </HeatmapContainer>
                      </Route>

                      <Route path="/admin">
                        <PermissionChecker role={roles.admin}>
                          <AdminView customerId={customer.customerId} />
                        </PermissionChecker>
                      </Route>

                      <Route path="/supportcenter">
                        <PermissionChecker role={roles.impersonator}>
                          <SupportView setCustomer={setCustomerHandler} />
                        </PermissionChecker>
                      </Route>

                      <Route path="/northstar">
                        <NorthstarView customerId={customer.customerId} />
                      </Route>

                    </>}
                  </UserContext.Provider>
                </> : <>

                  {hostType === 'HUB' ? <>
                    <ViewContainer>
                      <LocalSetupView />
                    </ViewContainer>
                  </> : <>

                    {user && venues && !customer ? <>
                      <ViewContainer style={{ padding: '50px 5px', boxSizing: 'border-box' }}>
                        <MyVenuesView
                          user={user}
                          venues={venues}
                          customer={customer}
                          setCustomer={setCustomerHandler}
                          showSignOutButton={true}
                        />
                      </ViewContainer>

                    </> : <>
                        <Switch>
                          
                          <Route exact path="/new-install">
                            <NewInstalationView/>
                          </Route>

                          <Route path="/:signInMethod?">
                            <CenteredViewContainer>
                              <AuthView setAuthenticated={setAuthenticated} />
                            </CenteredViewContainer>  
                          </Route>

                        </Switch>
                    </>}
                  </>}

                </>}
              </Switch>

            </> : <></>}

            {appState === appStates.LOADING ? <>
              <LoadingView />
            </> : <></>}

            {appState === appStates.SERVICE_UNAVAILABLE ? <>
              <ServiceUnavailableView />
            </> : <></>}
          </div>

        </Router>
      </StyleProvider>
    );
  }
}

export default App;
