import React, { useEffect, useState } from 'react';
import Button from '../UiComponents/Button';
import { SetupStepContainer, StepManagerContainer, SkipButtonContainer, AnimationOverlay, SetupStatusContainer, UserListContainer, HorizontalRadioContainer} from './SetupStepContainers';
import InputField from '../UiComponents/InputField';
import IconLogo from '../UiComponents/IconLogo';
import theme from '../../UI/theme';
import { FaTrash } from "react-icons/fa"
import Radio from '../UiComponents/Radio';

const ACCESS_LEVEL = {
    read: {value: 'read', label: 'Read'},
    basic: {value: 'basic', label: 'Basic'},
    admin: {value: 'admin', label: 'Admin'}
}


const UserSetup = ({ hubBluetoothConnection, setSetupStep, SETUP_STEP }) => {

    const [givenEmail, setGivenEmail] = useState('');
    const [email, setEmail] = useState('');
    const [accessLevel, setAccessLevel] = useState(ACCESS_LEVEL.admin);
    const [users, setUsers] = useState([]);
    const [validEmail, setValidEmail] = useState(false);
    const [pendingData, setPendingData] = useState(false);
    const dataPendingRef = React.useRef(pendingData);
    dataPendingRef.current = pendingData;

    useEffect(() => {
        syncData();
        hubBluetoothConnection.subscribeUsersData(subscriptionCallback);
    }, []);

    useEffect(() => () => {
        hubBluetoothConnection.unsubscribeUsersData(subscriptionCallback);
    }, []);

    function subscriptionCallback(event) {
            const value = new TextDecoder().decode(event.target.value);
            var parsedData = hubBluetoothConnection.parse(value);
            syncData();
    }

    function updateUsers() {
        setPendingData(true);
        const existingUser = users.find(user => user.email === givenEmail);
        if (existingUser) {
            setPendingData(false);
            return;
        };
        const newUser = {
            email: givenEmail,
            accessLevel: accessLevel
        }
        const updatedUsers = [...users, newUser];
        hubBluetoothConnection.setUsersData(updatedUsers);

        setTimeout(() => {
            if (dataPendingRef.current) syncData();
        }, 4250);
    }

    function removeUser(email) {
        setPendingData(true);
        const updatedUsers = users.filter(user => user.email !== email);
        hubBluetoothConnection.setUsersData(updatedUsers);

        setTimeout(() => {
            if (dataPendingRef.current) syncData();
        }, 4250);
    }

    async function getUsers() {
        return await hubBluetoothConnection.getUsersData();
    }

    async function syncData() {
        const recievedUsers = await getUsers();
        if (recievedUsers === false) return;
        if (recievedUsers.length) {
            setUsers(recievedUsers);
        } else setUsers([]);
        setPendingData(false);
    }

    function validateEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    function updateGivenEmailHandler(email) {
        setGivenEmail(email);
        if (validateEmail(email)) setValidEmail(true);
        else setValidEmail(false);
    }

    function next() {
        setSetupStep(SETUP_STEP.COMPLETE);
    }

    function back() {
        setSetupStep(SETUP_STEP.CLIENT);
    }

    function skip() {
        next();
    }

    return (
        <SetupStepContainer>
            <AnimationOverlay/>
            <h2>Users</h2>
            <p style={{width: '100%', maxWidth: '300px'}}>Email
                <InputField
                    value={givenEmail}
                    onClick={() => {setGivenEmail('')}}  
                    onChange={(e) => {updateGivenEmailHandler(e.target.value)}}
                    onBlur={(e) => {updateGivenEmailHandler(e.target.value)}}
                    placeholder='email'
                />
            </p>
            <h4 style={{marginBottom: '10px'}}>Choose Access Level</h4>
            <HorizontalRadioContainer style={{marginBottom: '30px'}}>
                <p>Read</p>
                <p>Basic</p>
                <p>Admin</p>
                <Radio
                    checked={ACCESS_LEVEL.read.value === accessLevel?.value}
                    onChange={() => { setAccessLevel(ACCESS_LEVEL.read)}}
                    disabled={!validEmail}
                />
                <Radio
                    checked={ACCESS_LEVEL.basic.value === accessLevel?.value}
                    onChange={() => { setAccessLevel(ACCESS_LEVEL.basic)}}
                    disabled={!validEmail}
                />
                <Radio
                    checked={ACCESS_LEVEL.admin.value === accessLevel?.value}
                    onChange={() => { setAccessLevel(ACCESS_LEVEL.admin)}}
                    disabled={!validEmail}
                />
            </HorizontalRadioContainer>
            <Button disabled={!validEmail} primary small onClick={updateUsers}>Add User</Button>

            {pendingData? 
                <SetupStatusContainer>
                    <p>One moment...</p>
                    <IconLogo preloader/>
                </SetupStatusContainer>
            : null}

            {users.length && !pendingData?
                 <SetupStatusContainer>
                    <UserListContainer>
                        <h4 style={{borderBottom: `1px solid ${theme.colors.greenEnergy}`, width: '100%', textAlign: 'center'}}>Users</h4>
                        <h4 style={{borderBottom: `1px solid ${theme.colors.greenEnergy}`, width: '100%', textAlign: 'center'}}>Access</h4>
                        <h4 style={{borderBottom: `1px solid ${theme.colors.greenEnergy}`, width: '100%', textAlign: 'center', color: 'rgba(0, 0, 0, 0)'}}>x</h4>
                        {users.map((user, index) => {
                            return ([
                                <p key={(3*index +1)}>{user.email}</p>,
                                <p key={(3*index +2)}>{user.accessLevel.label}</p>,
                                <p key={(3*index +3)} style={{color: theme.colors.greenEnergy, cursor: 'pointer'}} onClick={() => removeUser(user.email)}><FaTrash/></p>
                            ]);
                        })}
                    </UserListContainer>
                </SetupStatusContainer>
            : null}
            
            <StepManagerContainer>
                <Button disabled={false} tertiary small onClick={back}>Back</Button>
                <Button disabled={false} primary small onClick={next}>{users.length? 'Next' : 'Skip'}</Button>
            </StepManagerContainer>

            <SkipButtonContainer/>

        </SetupStepContainer>
    );
};

export default UserSetup;