import styled, { keyframes } from "styled-components";
import theme from "../../UI/theme";

const fadeIn = keyframes`
    from {  opacity: 1; }
    to { opacity: 0; }
`

const fadeInContent = keyframes`
    0% { opacity:0; }
    30% { opacity:0; }
    100% { opacity:1; }
`

const SetupContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 50px 100px;
    height: 100vh;

    h1 {
        color: ${theme.colors.greenEnergy};
    }

    @media (max-width: ${theme.screenSizes.medium}px) {
        padding: 50px 50px;
    }
    
    @media (max-width: ${theme.screenSizes.small}px) {
        padding: 10px 10px;
    }
`

const SetupStepContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: ${theme.colors.darkSpace90};
    padding: 0px 50px;

    h2 {
        color: ${theme.colors.greenEnergy};
    }

    @media (max-width: ${theme.screenSizes.medium}px) {
        
    }
    
    @media (max-width: ${theme.screenSizes.small}px) {
        padding: 0px 10px;
    }
`

const SetupStatusContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    animation: 1.1s ease 0s normal forwards 1 ${fadeInContent};

    p {
        text-align: center;
    }
`

const StepManagerContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    padding: 10px 10px;
    border-top: 1px solid ${theme.colors.greenEnergy};
    margin-top: auto;
    z-index: 11;
`

const SkipButton = styled.button`
    background-color: rgba(0,0,0,0);
    color: ${theme.colors.darkSpace30};
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.7rem;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: ${theme.colors.raspberryRed};
    }
`

const SkipButtonContainer = styled.div`
    min-height: 42px;
`

const ConnectionConfirmatoinContainer = styled.div`
    display: grid;
    grid-template-columns: 100px 160px;
    grid-template-rows: 25px;
    grid-auto-rows: 25px;
    row-gap: 5px;
    column-gap: 5px;
    justify-items: center;
    align-items: center;
    margin-top: 20px;   
`

const UserListContainer = styled.div`
    display: grid;
    grid-template-columns: 200px 75px 25px;
    grid-template-rows: 25px;
    grid-auto-rows: 25px;
    row-gap: 5px;
    justify-items: center;
    align-items: center;
    margin-top: 20px;   
`

const HorizontalRadioContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 30px;
    grid-auto-rows: 30px;
    column-gap: 5px;
    justify-items: center;
    align-items: center;
    min-width: 250px;
    max-width: 350px;
    width: 80%;
`

const AnimationOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 600px;
    z-index: 10;
    background-color: ${theme.colors.darkSpace90};
    animation: ${fadeIn} 0.9s;
    opacity: 0;
    pointer-events: none;
`

const ReviewContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    padding: 0px 80px;
    animation: 1.1s ease 0s normal forwards 1 ${fadeInContent};

    @media (max-width: 560px) {
        padding: 0px 20px;
    }
`

const ReviewItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
    row-gap: 5px;
    padding: 6px 0px;
    margin-bottom: 10px;


    h5 {
        margin: 0px;
        color: ${theme.colors.whiteSpace};
    }

`

const ReviewItemSummary = styled.div`
    display: grid;
    grid-template-columns: 80px 200px;
    column-gap: 10px;
    row-gap: 5px;
    margin-left: 20px;
    width: 100%;
    p {
        margin: 0px;
        font-size: 0.85rem;
    }

`

const ReviewAlternative = styled.div`
    width: 100%;
    margin-left: 20px;

    p {
        margin: 0px;
        font-size: 0.85rem;
    }

    a {
        font-size: 0.85rem;
        color: ${theme.colors.greenEnergy};

        &: hover {
            cursor: pointer;
            border-bottom: 1px solid ${theme.colors.greenEnergy};
        }
    }

    span {
       font-size: 0.85rem;
    }

`


export {
    SetupContainer,
    SetupStepContainer,
    SetupStatusContainer,
    StepManagerContainer,
    SkipButton,
    SkipButtonContainer,
    ConnectionConfirmatoinContainer,
    AnimationOverlay,
    UserListContainer,
    HorizontalRadioContainer,
    ReviewContainer,
    ReviewItem,
    ReviewItemSummary,
    ReviewAlternative
}